import Button from "@amzn/meridian/button";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import Row from "@amzn/meridian/row";
import Tag from "@amzn/meridian/tag";
import Text from "@amzn/meridian/text";
import React, { useCallback, useRef, useState } from "react";
import { useAppSelector } from "src/store/store";
import {
  downloadJMR,
  setIsConfirmDeleteModalOpen,
  setIsEmailModalOpen,
  setIsUpdateRestrictionDatesModalOpen,
  setIsUpdateStatusModalOpen,
  setIsConfirmUpdateRestrictionsModalOpen,
  setIsAlternativePlacementModalOpen,
  setIsInitiateTransferModalOpen,
  setIsOomdModalOpen,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import Icon from "@amzn/meridian/icon";
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import { useDispatch } from "react-redux";
import { setIsTwpaModalOpen } from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import * as LDAP from "src/config/LDAP";
import { COUNTRY_ID_MAPPING } from "src/config/CountryIdMapping";
import { useNavigate, useParams } from "react-router-dom";
import { setJmrFlowType, JmrFlowTypes } from "src/store/newJmrPageSlice";
import { ALTERNATIVE_PLACEMENT_RECOMMENDED, APPROVED_TWP, ASSOCIATE_DECISION_PENDING, DLS_REFERRAL_RECEIVED, INDEFINITE_RESTRICTIONS, PLACEMENT_DECISION_PENDING, TRANSFER_PENDING, TWP_BUT_PENDING_UPDATED_RESTRICTIONS } from "src/config/AccommodationStatusNames";

const topOptions = () => {
  let { placementId } = useParams();
  const navigate = useNavigate();
  const { featureFlags } = useAppSelector((state) => state.user);
  const { placementDetails, accommodationStatus } = useAppSelector((state) => state.placementDetailsPage);
  const { ldap } = useAppSelector((state) => state.user);
  const exportButtonRef = useRef();
  const updateButtonRef = useRef();
  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);
  const onClickExportButton = useCallback(() => setIsExportMenuOpen(true), []);
  const onCloseExportMenu = useCallback(() => setIsExportMenuOpen(false), []);
  const [isUpdateMenuOpen, setIsUpdateMenuOpen] = useState(false);
  const onClickUpdateButton = useCallback(() => setIsUpdateMenuOpen(true), []);
  const onCloseUpdateMenu = useCallback(() => setIsUpdateMenuOpen(false), []);

  const dispatch = useDispatch();

  const isAdmin = ldap.includes(LDAP.ADMIN);

  // these statuses are allowed to enter the update placement flow
  const STATUSES_ALLOWED_TO_UPDATE_PLACEMENTS = (featureFlags.DP_INTEGRATION_P0) ? [
    PLACEMENT_DECISION_PENDING, ASSOCIATE_DECISION_PENDING, APPROVED_TWP,
    INDEFINITE_RESTRICTIONS, TWP_BUT_PENDING_UPDATED_RESTRICTIONS,DLS_REFERRAL_RECEIVED
  ] : [PLACEMENT_DECISION_PENDING, ALTERNATIVE_PLACEMENT_RECOMMENDED];

  /**
   * opens update status modal
   * @returns {Object}
   */
  const handleOpenUpdateStatusModal = () => {
    dispatch(setIsUpdateStatusModalOpen(true));
  };

  /**
   * open update end date modal
   */
  const handleOpenUpdateEndDateModal = () => {
    dispatch(setIsUpdateRestrictionDatesModalOpen(true));
  };

  /**
   * opens email modal
   */
  const handleOpenEmailModal = () => {
    dispatch(setIsEmailModalOpen(true));
  };

  /**
   * opens update restrictions confirm modal
   */
  const handleUpdateRestrictionsModalOpen = () => {
    dispatch(setIsConfirmUpdateRestrictionsModalOpen(true));
  };

  /**
   * opens alternative placement confirm modal
   */
  const handleUpdateAlternativePlacementModalOpen = () => {
    dispatch(setIsAlternativePlacementModalOpen(true));
  };

  const handleInitiateTransferModalOpen = () => {
    dispatch(setIsInitiateTransferModalOpen(true));
  }

  /* * download the jmr file
   */
  const clickDownloadJmr = (newestJmrFileName: string) => {
    dispatch(downloadJMR(newestJmrFileName));
  };

  const isCanadaPlacement = () => {
    return placementDetails?.countryId === COUNTRY_ID_MAPPING.CANADA_COUNTRY_ID;
  };

  const handleAccceptTransfer = () => {
    navigate(`/newJmr/${placementId}/update/${JmrFlowTypes.CompleteTransfer}`);
    dispatch(setJmrFlowType(JmrFlowTypes.CompleteTransfer));
  }

  return (
    <Row maxWidth={"100%"} widths={["", "fill"]}>
      <Text type="d100" data-cy="placement-details__name-header">{`${
        placementDetails.associateFullName || placementDetails.login
      }`}</Text>
      <div>
        <Tag type="warning" data-cy="placement-details__status-tag">
          {`${placementDetails?.accommodationStatus?.status}`}
        </Tag>
      </div>

      {isAdmin && (
        <Button
          data-cy="placement-details__delete-btn"
          type="link"
          onClick={() => dispatch(setIsConfirmDeleteModalOpen(true))}
        >
          Delete Placement
        </Button>
      )}
      {placementDetails?.accommodationStatus?.status !== TRANSFER_PENDING && <>
        <Button type="secondary" onClick={handleOpenEmailModal} data-cy="placement-detail-send-email__btn">
          Send Email
        </Button>
        <Button
          id="update-placement-open-menu-btn"
          data-cy="placement-detail__update-btn"
          type="primary"
          ref={updateButtonRef}
          onClick={() => onClickUpdateButton()}
        >
          Update
          <Icon tokens={chevronDownSmallTokens} />
        </Button>
        <Menu
          anchorNode={updateButtonRef.current}
          open={isUpdateMenuOpen}
          position="bottom"
          onClose={onCloseUpdateMenu}
          data-cy="placement-detail__update-menu"
        >
          {accommodationStatus && accommodationStatus.length > 0 && <MenuItem onClick={handleOpenUpdateStatusModal} data-cy="placement-details__status-btn">
            Status
          </MenuItem>}
          <MenuItem onClick={handleOpenUpdateEndDateModal} data-cy="placement-details__extend-end-date-btn">
            Restriction Dates
          </MenuItem>
          <MenuItem onClick={handleUpdateRestrictionsModalOpen} data-cy="placement-details__restrictions-btn">
            Restrictions
          </MenuItem>
          {STATUSES_ALLOWED_TO_UPDATE_PLACEMENTS.includes(placementDetails?.accommodationStatus?.status) && (
            <MenuItem
              onClick={handleUpdateAlternativePlacementModalOpen}
              data-cy="placement-details__alternative-placement-btn"
            >
              Placement Selection(s)
            </MenuItem>
          )}
          {featureFlags.PLACEMENT_TRANSFER && placementDetails.accommodationType === "AUSTIN"
            && <MenuItem
              onClick={handleInitiateTransferModalOpen}
              data-cy="placement-details__initiate-transfer-btn"
            >
              Initiate Transfer
            </MenuItem>}
      </Menu>
      <Button type="primary" ref={exportButtonRef} onClick={() => onClickExportButton()}>
        Export
        <Icon tokens={chevronDownSmallTokens} />
      </Button>
      <Menu anchorNode={exportButtonRef.current} open={isExportMenuOpen} position="bottom" onClose={onCloseExportMenu}>
        {placementDetails.newestJmrFileName && (
          <MenuItem onClick={() => clickDownloadJmr(placementDetails.newestJmrFileName)}>Export JMR</MenuItem>
        )}
        {isCanadaPlacement() ? (
          <MenuItem onClick={() => dispatch(setIsOomdModalOpen(true))}>OOMD PDF</MenuItem>
        ) : (
          <MenuItem onClick={() => dispatch(setIsTwpaModalOpen(true))}>TWPA PDF</MenuItem>
        )}
      </Menu>
      </>}

      {placementDetails?.accommodationStatus?.status === TRANSFER_PENDING && <Button type="primary" onClick={handleAccceptTransfer} data-cy="placement-details__accept-transfer-btn">
        Accept Transfer
      </Button>}
    </Row>
  );
};

export default topOptions;
